<script>
import CandidateSuccessTemplate from "@/components/templates/CandidateSuccessTemplate.vue";

export default {
  name: "CandidateSuccessPage",
  components: { CandidateSuccessTemplate },
  data() {
    return {};
  },
  methods: {
    goToWebsite() {
      window.location.href = "https://www.literato.net";
    },
  },
};
</script>

<template>
  <candidate-success-template @click="goToWebsite" />
</template>

<style lang="scss" scoped></style>
