<script>
import AppButton from "@/components/atoms/AppButton.vue";
import ModularCard from "@/components/molecules/ModularCard.vue";

export default {
  name: "CandidateSuccessTemplate",
  components: { AppButton, ModularCard },
  data() {
    return {
      thankYouCard: {
        0: {
          type: "title",
          title: "Cadastro realizado com sucesso!",
        },
        1: {
          type: "image",
          image: "success",
        },
        2: {
          subtitle: "Seu Cadastro foi efetuado com sucesso!",
          type: "subtitle",
        },
        3: {
          type: "buttons",
        },
      },
    };
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <span class="section-container message-template" style="padding: 16px">
    <modular-card
      :cardModules="thankYouCard"
      customStyle="min-height: 100% !important; justify-content: flex-start; gap: 32px;"
    >
      <app-button
        name="go-home"
        text="Voltar para o Website"
        type="new-default"
        icon="mdi-open-in-new"
        @click="emit('goToWebsite')"
        customClass="independent-button"
      />
    </modular-card>
  </span>
</template>

<style lang="scss">
.message-template {
  min-height: calc((var(--vh, 1vh) * 100)) !important;
  display: flex;

  @media screen and (min-height: 800px) {
    max-height: calc((var(--vh, 1vh) * 100)) !important;
    align-items: center !important;
  }
}
</style>
